import React from "react"
import { graphql } from "gatsby"
import JupyterNotebook from "../components/jupyter-notebook/jupyter-notebook"

export default function Template({data}:any) {
  const { notebook } = data
  return (
    <>
      <JupyterNotebook json={notebook.json}></JupyterNotebook>      
    </>
  )
}

// TODO: Add dummy data for fields to prevent crashing if not exist. 
// text_html
// image_svg_xml
export const pageQuery = graphql`
  query($id: String!) {
    notebook: jupyterNotebook(parent: { id: { eq: $id } }) {
      id
      json {
        nbformat
        cells {
          source
          outputs {
            data {
              text_plain
              text_markdown
              image_png
            }
            ename
            evalue
            execution_count
            name
            traceback
            text
            output_type
          }
          id
          execution_count
          cell_type
        }
        metadata {
          language_info {
            codemirror_mode {
              name
              version
            }
            file_extension
            mimetype
            name
            nbconvert_exporter
            pygments_lexer
            version
          }
          kernelspec {
            display_name
            language
            name
          }
        }
      }
      parent {
        ... on File {
          id
          name
        }
      }
    }
  }
`