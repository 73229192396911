import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import Code from '../markdown/code';
import Markdown from '../markdown/markdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
    grow: {
      flexGrow: 1,
    },
    story: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      textAlign: 'left'
    },
    action: {
        margin: theme.spacing(1),
    },
    centered: {
      maxWidth:'640px',
      margin:'auto'
    },
    overflow: {
      overflowY: 'auto',
      height: '100px'
    },
    confusion: {
      fontStyle: 'italic',
      color: 'red'
    },
    emphasis: {
      fontWeight: 'bold',
      color: theme.palette.primary.main
    },
    link: {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  })
)

const Error = ({data, language, isOutput}:any) => {
  console.log(data)
  return <Code language={language} isOutput={isOutput}>Error: Cannot display the following: {JSON.stringify(data)}</Code>
}

const BlockSource = ({cell,language}:any) => {
  switch (cell.cell_type) {
    case 'code':
      return <Code language={language}>{cell.source.join('')}</Code>
    case 'markdown':
      return <Markdown>{cell.source.join('')}</Markdown>
    default:
      return <Error data={cell} language={language} isOutput={false} />
  }
}

const BlockOutput = ({cell,language}:any) => {
  const outputs = cell?.outputs.map((output:any, index:number) => {
    switch (output.output_type) {
      case 'execute_result':
        return <Code key={index} language={language} isOutput={true}>{output.data.text_plain.join('')}</Code>
      case 'stream':
        return <Code key={index} language={language} isOutput={true}>{output.text.join('\n')}</Code>
      case 'display_data':
        if (output.data['image_png']) {
          return <Box key={index}><img src={`data:image/png;base64, ${output.data['image_png']}`} /></Box>
        } else if(output.data['image_svg_xml']) {
          return <Box key={index}><img src={output.data['image_svg_xml']} />
            <Error data={output.data['image_svg_xml']} isOutput={true} />
          </Box>
        } else if (output.data['text_markdown']) {
          return <Markdown key={index}children={output.data['text_markdown']} />
        } else if(output.data['text_html']) {
          return <Box key={index} dangerouslySetInnerHTML={output.data['text_html']()} />
        } else if(output.data['text_plain']) {
          return <Code key={index} language={language} isOutput={true}>{output.data['text_plain']}</Code>
        }
        return <Error key={index} data={output} language={language} isOutput={true} />
      default:
        return <Error key={index} data={output} language={language} isOutput={true} />
    }
  })
  return <div>{outputs}</div>
}

const getLanguage = (kernelspec:any) => {
  return kernelspec?.language || ''
}

const JupyterNotebook = ({json}:any) => {
  const classes = useStyles()
  const language = getLanguage(json?.kernel)
  const blocks = json?.cells?.map((cell:any, index:number) => {
    return (
      <Box className={classes.story} key={index}>
        {cell.cell_type &&
          <BlockSource
            cell={cell}
            language={language}
          />
        }
        {cell?.outputs?.length > 0 &&
          <BlockOutput
            cell={cell}
            language={language}
          />
        }
      </Box>
    )
  })
  return (
    <>
      <div>{blocks}</div>
    </>
  )
}

export default JupyterNotebook